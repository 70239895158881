import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';

const Thanks = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>{t('contact.thank_you')}</h1>
            <p>{t('contact.thank_you_description')}</p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Thanks;
